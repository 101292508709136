const currencyTransform = function (price) {
  if (!price) return 0
  const rateList = useCookie('SDT_RateList').value || [{ currency: 'CNY', rate: 1 }]
  const userCurrency = useCookie('SDT_MonetaryUnit').value?.currency || 'CNY'
  for (const currency of rateList) {
    if (currency.currency === userCurrency) {
      return Number((price * currency.rate).toFixed(2))
    }
  }
}
export default currencyTransform
